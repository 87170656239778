import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Grid } from '../../../components/grid';
import { Link } from 'gatsby';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/getting-started/working-guide/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{props.pageContext.frontmatter.description}</p>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Getting help from the DSYS Team`}</h2>
        <h3>{`When should you talk to the Design Systems team?`}</h3>
        <p>{`While `}<strong parentName="p">{`Product Designers at Twilio are expected to partner`}</strong>{` with the Design Systems team for their work, we recommend that all Paste users (PMs, Engineers, etc.) engage with us early, and often! The DSYS team provides `}<a parentName="p" {...{
            "href": "/getting-started/working-guide#design-systems-meetings"
          }}>{`many ways to connect with the team that are timezone friendly`}</a>{`, and offer a safe, inclusive space to incubate your ideas as they becomes great Twilio experiences. Here are some `}<em parentName="p">{`checkpoints`}</em>{` that are great times to reach out to our team.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`After your first week at Twilio!`}</strong>{` Come say hi at #help-design-system on Slack and introduce yourselves! We'd love to hear your journey, and share how our team works.`}</li>
          <li parentName="ul">{`You (or a representative designer from your group) will be invited every `}<strong parentName="li">{`Quarter`}</strong>{` to our roadmap planning sessions: share what's coming up from a UI perspective, and any components or compositions that you believe will be essential to build a great customer experience. Post in #help-design-system if you have questions.`}</li>
          <li parentName="ul"><strong parentName="li">{`At the start of a project`}</strong>{` - reach out to us at our weekly office hours with a heads up on the project, any deadlines for delivery, and any components or compositions that you're confident will be necessary.`}</li>
          <li parentName="ul"><strong parentName="li">{`At the start of medium or high fidelity design.`}</strong>{` This is a great time to share your specific needs with the DSYS team. You may also begin to receive feedback on accessibility or pattern usage at this stage.`}</li>
          <li parentName="ul"><strong parentName="li">{`Before user testing or UX reviews.`}</strong>{` Before you share your ideas with customers or get a sign off on the final design, drop by for a deep dive on copy, component and composition usage, accessibility, and other design considerations.`}</li>
          <li parentName="ul"><strong parentName="li">{`Before launch.`}</strong>{` We'll use this time to do a final polish on the work, and discuss what changes we may need to make after your product's released.`}</li>
        </ul>
        <h3>{`What kind of support can you expect from the Design Systems team?`}</h3>
        <p>{`We're excited to partner with you on building better products. Towards this, you can expect the following levels of support from the team:`}</p>
        <ul>
          <li parentName="ul">{`Request components or patterns that you may need for your customer experiences.`}</li>
          <li parentName="ul">{`Get feedback on usage of Paste components and compositions in your mocks and/or UIs.`}</li>
          <li parentName="ul">{`Get information about building more accessible UIs, and any common pitfalls that one might typically encounter.`}</li>
          <li parentName="ul">{`Get support on hierarchy, weight, and spacing in your UIs.`}</li>
          <li parentName="ul">{`Get answers to clarifying questions about Design Systems concepts.`}</li>
          <li parentName="ul">{`Verify if your UIs are aligned with the `}<a parentName="li" {...{
              "href": "https://paste.twilio.design/content"
            }}>{`Paste content guidelines`}</a></li>
          <li parentName="ul">{`Get constructive feedback that helps your UI follow design system and accessibility conventions that are expected for Twilio UX reviews.`}</li>
        </ul>
        <p>{`It's important to note that the Design Systems team does not directly talk to customers, and is `}<strong parentName="p">{`not a replacement for research and customer understanding`}</strong>{`. While we're happy to provide feedback on the product decisions made, we recommend testing with customers or customer proxies to better understand if we're solving for their needs.`}</p>
        <h3>{`Support questions, feature requests, and bug reports`}</h3>
        <ul>
          <li parentName="ul">{`We recommend searching this doc site before reaching out to the team for help. It may be the quickest way to get you going.`}</li>
          <li parentName="ul">{`Still need help? `}<a parentName="li" {...{
              "href": "https://github.com/twilio-labs/paste/discussions/new"
            }}>{`Start a discussion`}</a>{` on GitHub. Paste is free and open-source, so all you need is a free GitHub account to post questions.`}</li>
          <li parentName="ul">{`Spotted a bug or error? `}<a parentName="li" {...{
              "href": "https://github.com/twilio-labs/paste/issues/new?assignees=&labels=Type%3A+Bug&template=bug_report.md&title="
            }}>{`File an issue`}</a></li>
        </ul>
        <h3>{`Design Systems Meetings`}</h3>
        <p>{`These meetings should be visible on the UX Calendar. If you have trouble finding them, reach out at #help-design-system on Slack.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Weekly Design System office hours`}</strong>{` on Thursdays alternating North America and Europe friendly time zones. Use these sessions to plan out your UI needs, get feedback on implementation, or debug an issue.`}</li>
          <li parentName="ul"><strong parentName="li">{`Weekly design critique`}</strong>{` on Tuesdays that are specifically a space for product designers to get feedback on their work.`}</li>
          <li parentName="ul"><strong parentName="li">{`Monthly Design System guild meeting`}</strong>{` for developers, designers, and product managers interested in building better experiences for customers.`}</li>
        </ul>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      